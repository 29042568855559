
import { Component, Vue } from "nuxt-property-decorator";
//@ts-ignore
import Swiper from "@/node_modules/swiper/swiper-bundle.min";
import "swiper/swiper-bundle.min.css";
import { DocumentWithLink } from "@/types/responses/inserzione/ResponseInserzioneInterface";

@Component
export default class WorkingWith extends Vue {
  public partners: Array<DocumentWithLink> = [];

  async fetch() {
    try {
      const { data } = await this.$axios.get(this.$config.astaEndpoint + "lavoriamo-con");
      this.partners = data.data;
    } catch (e) {
      if (e instanceof Error) {
        console.error(e);
      }
      throw e;
    }
  }
  fetchOnServer() {
    return false;
  }

  async mounted() {
    await this.$nextTick();
    await new Swiper(this.$refs.homeswiper, {
      grabCursor: true,
      spaceBetween: 10,
      slidesPerView: 1,
      autoplay: {
        delay: 2500,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      breakpoints: {
        // when window width is >= 320px
        500: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        // when window width is >= 480px
        1111: {
          slidesPerView: 5,
          spaceBetween: 20,
        },
      },
      pagination: {
        el: ".swiper-pagination-lavoriamo-con",
        bulletActiveClass: "swiper-pagination-bullet-active swiper-pagination-lavoriamo-con-bullet-active",
        bulletClass: "swiper-pagination-bullet swiper-pagination-lavoriamo-con-bullet",
        type: "bullets",
        clickable: true,
      },
    });
  }
}
