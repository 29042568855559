import { render, staticRenderFns } from "./WorkingWith.vue?vue&type=template&id=f4bfe9ca&scoped=true"
import script from "./WorkingWith.vue?vue&type=script&lang=ts"
export * from "./WorkingWith.vue?vue&type=script&lang=ts"
import style0 from "./WorkingWith.vue?vue&type=style&index=0&id=f4bfe9ca&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f4bfe9ca",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CoreErrorsAlertAction: require('/usr/src/nuxt-app/components/core/errors/AlertAction.vue').default})
